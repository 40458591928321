.Sell {
  display: flex;
  justify-content: center;
  gap: 4rem;
  padding-top: 1rem;
  overflow: hidden;

  width: 100%;
}

.Sell .images {
  width: 50%;
  position: relative;
  height: 700px;
  margin-right: 40px;
}
.Sell .images2 {
  display: none;
}
.Sell .images3 {
  display: none;
}
.Sell .images4 {
  display: none;
}
.Sell .images img {
  top: 0;
  position: absolute;
  width: 80%;
  height: 45%;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
  border: 5px solid #fff;
}
.Sell .images .img1 {
  top: 0%;
  right: 10%;
}
.Sell .images .img2 {
  top: 20%;
  right: 30%;
}
.Sell .images .img3 {
  top: 40%;
  right: 0%;
}
.Sell .text {
  width: 50%;

  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.Sell .text h1 {
  font-size: 48px;
  line-height: 120%;
  margin: 16px 0 0;
}
.Sell .text p {
  font-size: 18px;
  line-height: 180%;
  margin: 16px 0 0;
}
.Sell .text ul {
  font-size: 18px;
  line-height: 180%;
  margin: 16px 0 0;
  padding-left: 20px;
}

@media (max-width: 1289px) {
  .Sell {
    margin-top: 0;
    margin-bottom: 0;
  }
}
@media (max-width: 899px) {
  .Sell {
    gap: 0rem;
    flex-wrap: wrap;
  }

  .Sell .images {
    margin-right: 20px;
    width: 100%;
  }
  .Sell .images img {
    width: 80%;
    height: 50%;
  }
  .Sell .text {
    width: 100%;
  }
  .Sell .text h1 {
    font-size: 37px;
  }
  .Sell .text p {
    font-size: 17px;
  }
  .Sell .text ul {
    font-size: 17px;
    padding-left: 15px;
  }
}

@media (max-width: 599px) {
  .Sell {
    gap: 1rem;
    flex-wrap: wrap;
  }

  .Sell .images {
    width: 100%;
    height: 400px;
  }
  .Sell .images img {
    width: 80%;
  }
  .Sell .images .img1 {
    top: 0%;
    right: 10%;
  }
  .Sell .images .img2 {
    top: 20%;
    left: 20%;
  }
  .Sell .images .img3 {
    top: 40%;
    right: 20%;
  }
  .Sell .text {
    width: 100%;
  }
  .Sell .text h1 {
    font-size: 36px;
    line-height: 120%;
  }
  .Sell .text p {
    font-size: 16px;
  }
  .Sell .text ul {
    font-size: 16px;
  }
}
