.Manage {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4rem;
  margin: 3.5rem 0;
  overflow: hidden;

  width: 100%;
  height: 100%;
}

.Manage .images {
  flex-shrink: 0;
  width: 50%;
  position: relative;
  height: 650px;
  margin-top: 0;
}
.Manage .images .manage2 {
  display: none;
}
.Manage .images img {
  top: 0;
  bottom: 0;
  right: 50px;
  position: absolute;
  width: 90%;
  height: 100%;
  object-fit: cover;
}
.Manage .text {
  width: 50%;

  display: flex;
  flex-direction: column;
}
.Manage .text .logoText {
  display: flex;
  align-items: center;

  gap: 20px;
  margin: 10px 0 30px;
}
.Manage .text .logoText .img {
  height: 40px;
  margin: 15px 0 0;
}
.Manage .text .logoText img {
  width: 100%;
  object-fit: cover;
}
.Manage .text .logoText p {
  max-width: 320px;
}
.Manage .text h1 {
  font-size: 48px;
  line-height: 120%;
  margin: 16px 0 0;
}
.Manage .text p {
  font-size: 18px;
  line-height: 180%;
  margin: 16px 0 0;
}
.Manage .text ul {
  font-size: 18px;
  line-height: 180%;
  margin: 16px 0 0;
  padding-left: 20px;
}

@media (max-width: 899px) {
  .Manage {
    gap: 3rem;
    margin: 4rem 0 3rem;
    flex-wrap: wrap-reverse;
  }

  .Manage .images {
    margin-right: 20px;
    width: 100%;
  }
  .Manage .text {
    width: 100%;
  }
  .Manage .images img {
    object-fit: cover;
    height: 100%;
  }

  .Manage .text h1 {
    font-size: 37px;
  }
  .Manage .text p {
    font-size: 17px;
  }
  .Manage .text ul {
    font-size: 17px;
    padding-left: 15px;
  }
  .Manage .text .logoText .img {
    height: 35px;
  }
}

@media (max-width: 599px) {
  .Manage {
    gap: 1rem;
    flex-wrap: wrap-reverse;
  }

  .Manage .images {
    width: 100%;
    height: 400px !important;
  }
  .Manage .images img {
    height: 100%;
    right: 0;
  }
  .Manage .text {
    width: 100%;
  }
  .Manage .text h1 {
    font-size: 36px;
    line-height: 120%;
  }
  .Manage .text p {
    font-size: 16px;
  }
  .Manage .text ul {
    font-size: 16px;
  }

  .Manage .text .logoText .img {
    height: 30px;
    margin: 15px 0 0;
  }
}
