.btn {
  background: none;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  width: max-content;

  gap: 6px;

  margin-top: 40px;
  transition: all 0.3s ease-in-out;
}
.btn .text {
  font-size: 18px;
  font-weight: 700;
  white-space: nowrap;
  word-wrap: break-word;
  width: 100%;
  transition: all 0.1s ease-in-out;
  border-bottom: 3px solid transparent;
}
.btn:hover > .text {
  border-bottom: 3px solid #fae053;
}
.btn:hover > .img {
  transform: translateX(4px);
}
.btn .img {
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn .img img {
  height: 100%;
  object-fit: cover;
}

.bgbtn {
  display: inline;
  width: min-content;
  padding: 14px 38px;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  mix-blend-mode: multiply;
  text-decoration: none;
  letter-spacing: 0;
  background-color: #000;
  border: 2px solid #000;
  border-radius: 6px;
  outline: none;
  display: inline-block;
  white-space: nowrap;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.35);
  transition: transform 0.2s linear, box-shadow 0.1s linear,
    background-color 0.1s linear;
}

.bgbtn:hover {
  transform: translateY(-8px);
  box-shadow: 0 10px 32px 0 rgba(0, 0, 0, 0.35);
}

.CardWarp {
  background: #fff;
  box-shadow: 4px 4px 16px 2px rgba(0, 0, 0, 0.1);
  padding: 60px 50px;
  /* min-height: 620px; */
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}
.CardWarp:hover {
  background: #f9fafb;
}
.CardWarp:hover > .cardBtn > span:nth-child(1) {
  border-bottom: 3px solid #fae053;
}
.CardWarp:hover > .cardBtn > span:nth-child(2) {
  transform: translateX(4px);
}
.CardWarp .icon {
  position: relative;
  height: 100px;
  margin: 10px 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.CardWarp .icon img {
  margin: auto;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.CardWarp h2 {
  color: #0a0c0f;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 16px;
}
.CardWarp ul li {
  font-weight: 500;
  font-size: 19px;
  margin: 16px 0 0;
  line-height: 160%;
}
.CardWarp .cardBtn {
  margin: 50px 0 0;
}

@media (max-width: 999px) {
  .CardWarp {
    padding: 30px;
    min-height: 620px;
  }
  .CardWarp h2 {
    font-size: 28px;
    line-height: 36px;
  }
  .CardWarp ul li {
    font-size: 19px;
  }
}

@media (max-width: 599px) {
  .btn .text {
    font-size: 16px;
    font-weight: 700;
  }
  .btn .img {
    height: 10px;
  }
  .bgbtn {
    padding: 10px 26px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }
  .CardWarp {
    padding: 25px 30px;

    min-height: auto !important;
  }
  .CardWarp .icon {
    position: relative;
    height: 80px;
    margin: 8px 0 15px;
  }
  .CardWarp h2 {
    font-size: 24px;
    line-height: 35px;
    margin-bottom: 14px;
  }
  .CardWarp ul li {
    font-size: 16.5px;
    margin: 12px 0 0;
    line-height: 160%;
  }
  .CardWarp ul {
    margin-left: 20px;
  }
  .CardWarp .cardBtn {
    margin: 40px 0 0;
  }
}
