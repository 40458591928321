.Grow{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4rem;
    margin: 2rem 0;
    overflow: hidden;
}

.Grow .images{
   width: 50%;

}
.Grow .images video {
  border-radius: 10px;
  object-fit: cover;
  object-position: center;
   width: 100%;
   box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}
.Grow .text{
   width: 50%;
   
   display: flex;
   flex-direction: column;


}
.Grow .text h1{
  font-size: 48px;
  line-height: 120%;
  margin: 16px 0 0;


}
.Grow .text p{
  font-size: 18px;
  line-height: 180%;
  margin: 16px 0 0;


}
.Grow .text ul{
  font-size: 18px;
  line-height: 180%;
  margin: 16px 0 0;
  padding-left: 20px;


}


@media (max-width:599px) {
  .Grow{
     gap: 1rem;
     flex-wrap: wrap;

 }
 
 .Grow .images{
    width: 100%; 
 }
 .Grow .images video {
    width: 100%;
 }
 .Grow .text{
    width: 100%;
 }
 .Grow .text h1{
   font-size: 36px;
   line-height: 120%;  
 
 }
 .Grow .text p{
   font-size: 16px;  
 }
 .Grow .text ul{
   font-size: 16px;
 }
 
}

@media (max-width:899px) {
  .Grow{
    flex-wrap: wrap;

     gap: 2rem;
     margin: 4rem 0 3rem;
 }
 
 .Grow .images{
 margin-right: 20px;
 width: 100%;
 }
 .Grow .images img {
   width: 100%;
   height: 50%;

}
.Grow .text{
  width: 100%;
}

.Grow .text h1{
  font-size: 37px;


}
.Grow .text p{
  font-size: 17px;


}
.Grow .text ul{
  font-size: 17px;
  padding-left: 15px;


}


}
