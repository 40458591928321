.Wrapper{
  display: flex;
  align-items: center;
  justify-content: center; 
  margin: 6rem 0;
  overflow: hidden;

}

.wrapBg {
  background-color: #f9fafb;
  margin: 0 auto;
  max-width: 2000px;
  
  padding-left: 10rem;
  padding-right: 10rem;
}

.Payment {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 40px; */
  max-width: 1600px;
  gap: 4rem;
  margin: 4rem 0;
}

.Payment .images {
  width: 50%;
}
.Payment .images img {
  width: 100%;
}
.Payment .text {
  width: 50%;

  display: flex;
  flex-direction: column;
}
.Payment .text .btn {
  margin: 40px 0 0;
}
.Payment .text h1 {
  font-size: 48px;
  line-height: 120%;
  margin: 16px 0 0;
}
.Payment .text p {
  font-size: 18px;
  line-height: 180%;
  margin: 16px 0 0;
}
.Payment .text .smallText {
  font-size: 12px;
  margin: 8px 0 0;
}
@media (max-width:1200px) {
  .wrapBg {
    
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
    
    @media (max-width:599px) {
      .wrapBg {
        
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }