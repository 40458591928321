.Hero{
    height: fit-content;
    max-width: 100%;
    overflow: hidden;    
}

.Hero .Row {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
}

.Hero .Row .detail{
    grid-column: span 6;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 10;
    padding-top: 100px;
}
.Hero .Row .detail .firstDetail{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 40px !important;
}

.Hero .Row .detail > div{
    height: 100vh;
    gap: 20px;
    padding-top: 10vh;
}

.Hero .detail h1{
    font-size: 36px;
    line-height: 120%;
    color: #000;
}
.Hero .detail .dvch2{
    font-size: 55px;
    line-height: 120%;
    color: #203764;
    white-space: nowrap;
    
}

.Hero .detail .underline{
    position: relative;
    width: fit-content;

    white-space: nowrap !important;

}
.Hero .detail .text{
    position: relative;
    z-index: 10;

}
.Hero .detail .line{
    z-index: 5;
    width: calc(100% - 8px);
    height: 20px;
    bottom: 10px;
    left: 0;
    position: absolute;
    background: #6b8dd4;
    display: none;
}

.Hero .detail .index-module_type__E-SaG::after{
    font-weight: 300;
    margin-left: -2px;
}

.Hero .detail h2{
    font-size: 48px;
    line-height: 120%;
    color: #000;
}

.Hero .detail p{
    font-size: 18px;
    line-height: 150%;
    color: #0a0c0f;
    padding-right: 20%;
    margin-bottom: 20px;
}
.Hero .detail ul li{
    font-size: 18px;
    line-height: 150%;
    color: #0a0c0f;
    font-weight: 500;
}

.Hero .detail .logo{
    display: flex;
    width: 100%;
    gap: 20px;
    margin-bottom: 20px;

    mix-blend-mode: multiply;
}
.Hero .detail .logo .imgWhite{
   filter: brightness(100) invert(0);
   transition: filter 0.1s;
   transition-timing-function: ease-out;
   transition-delay: 0.6s;
}
.Hero .detail .logo .divider{
    height: 100%;
    border-left: 1px solid #000;
}
.Hero .detail .logo img{
    width: 185px;
    height: 56px;
    mix-blend-mode: multiply;
}

.Hero .detail .logo p{
    margin-bottom: 0px;
}


.Hero .detail .btn{
    padding: 14px 38px;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #fff;
    text-decoration: none;
    letter-spacing: 0;
    background-color: #6b8dd4;
    border:none;
    border-radius: 6px;
    outline: none;
    display: inline-block;
    white-space: nowrap;
    box-shadow: 0 6px 16px 0 rgba(0,0,0,.35);
    transition: transform .1s linear,box-shadow .1s linear,background-color .1s linear;
}

.Hero .Row .detail .btn:hover{
    transform: translateY(-5px) !important;
    box-shadow: 0 10px 32px 0 rgba(0,0,0,.35);

}
.Hero .detail .dynamicBg{
    padding: 14px 38px;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    mix-blend-mode: multiply;
    color: #0a0c0f;
    text-decoration: none;
    letter-spacing: 0;
    background-color: #6b8dd4;
    border:none;
    border-radius: 6px;
    outline: none;
    display: inline-block;
    white-space: nowrap;
    box-shadow: 0 6px 16px 0 rgba(0,0,0,.35);
    transition: transform .1s linear,box-shadow .1s linear,background-color .1s linear;
}

.Hero .Row .detail .dynamicBg:hover{
    transform: translateY(-5px) !important;
    box-shadow: 0 10px 32px 0 rgba(0,0,0,.35);

}

.Hero .anim-mocks{
    grid-column: span 6;
    display: flex;
    align-items: center;
    height: 100vh;
    transform-origin: right top;
    padding-left: 90px;
}


.Hero .phone-frame{
    flex-shrink: 0;
    padding-top: 0px;
    position: relative;
    width: 348px;
    height: 556px;
    margin-right: -80px;
    z-index: 20;
}

.Hero .phone-cover-wrapper{
    width: 197px;
    height: 426px;
    left: 24px;
    top: 22px;
    position: absolute;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
}
.Hero .phone-cover{
    width: fit-content;
    height: 100%;
    display: flex;
}

.Hero .phone-cover img{
    width: 197px;
    height: 100%;
    object-fit: cover;
}

.Hero .phone-frame .phone-mock{
    display: block;
    height: 100%;
    position: absolute;
    object-fit: contain;
}

.Hero .tablet-frame{
    flex-shrink: 0;
    position: relative;
    margin-top: 50px;
    
    width: 1044px;
    height: 745px;
    z-index: 20;

}

.Hero .tablet-cover-wrapper{
    width: 860px;
    height: 592px;
    left: 50px;
    top: 24px;
    position: absolute;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
}
.Hero .tablet-cover{
    width: fit-content;
    height: 100%;
    display: flex;
}

.Hero .tablet-cover img{
    width: 860px;
    height: 100%;
    object-fit: cover;
}

.Hero .tablet-frame .tablet-mock{
    display: block;
    height: 100%;
    position: absolute;
    object-fit: contain;

}

.Hero .anim-mocks .bgBox{
    position: absolute;
    height: 300vh;
    width: 100vw;
    z-index: 5;
    left: 210px;
    top: -100%;
    background: #6b8dd4;
}

.Hero .anim-mocks .bgBox2{
    position: absolute;
    min-height: 85vh;
    width: 100vw;
    z-index: 5;
    right: 100%;
    top: 59%;
    background: #6b8dd4;
}

#secondDetail{
    justify-content: center;
    padding-top: 0 !important;
}

.Hero .anim-mocks .glasses{
    position: absolute;
    left: 60%;
    z-index: 20;
    top: -13vw;
    width: 373px;
}
.Hero .anim-mocks .glasses img{
    width: 100%;
    display: block;
}

.arrowDown{
    position: fixed;
    top: 93vh;
    left: 50%;
    transform: translateX(-50%) rotate(90deg);
    animation-name: animate--jump;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    cursor: pointer;
}

@keyframes animate--jump {
    0%, 100%, 20%, 50%, 80% {
        transform: translateY(0) rotate(90deg);
    }
    40% {
        transform: translateY(-14px) rotate(90deg);
    }
    60% {
        transform: translateY(-7px) rotate(90deg);
    }
}

.Hero .detail .underline *{
    text-decoration: underline;
    text-decoration-color: #6b8dd4;
    text-decoration-thickness: 13px;
    text-decoration-skip-ink: none;
    text-underline-offset: -7px;
}
@media (max-width:1309px) {
    .Hero .anim-mocks .bgBox2{
        min-height: 870px;
    

    }
    
}

@media (max-width:1109px) {
    .Hero .anim-mocks .bgBox2{
        min-height: 100vh;
        right: 100%;

    }
   
.Hero .Row .detail > div{
    height: 87vh;
    padding-top: 15vh;
}
    
}
@media (max-width:959px) {
    .Hero .anim-mocks .bgBox2{
        min-height: 75vh;

    }
   
.Hero .Row .detail > div{
    height: 95vh;
    padding-top: 10vh;
}
   
.Hero .detail .dvch2{
    font-size: 45px;
    white-space: nowrap;
    
}
#secondDetail h2{
    font-size: 35px;
}
#secondDetail p{
    font-size: 16px;
}
    
}

@media (max-width:659px) {
    .Hero .detail .underline{
        position: relative;
        width: fit-content;
    
        white-space: wrap !important;
    
    }
    .Hero .detail .dvch2{
        font-size: 38px;
        white-space: wrap;
        
    }
    .Hero .detail .underline *{
        text-decoration: underline;
        text-decoration-color: #6b8dd4;
        text-decoration-thickness: 10px;
        text-decoration-skip-ink: none;
        text-underline-offset: -5px;
    }

    .Hero .imgCon{
        width: 100%;
    }
    .Hero .imgCon img{
        margin: 0 -10px;
        display: block;
        height: 100%;
        width: 150%;
        object-fit: contain;
    }

    .Hero .detail{
        padding-top: 120px;
        display: flex;
        flex-direction: column;
        align-items: start;
        gap:50px;
        position: relative;
        padding-bottom: 100px;
        width: 100%;
    }
   
    .Hero .detail .bgBox3{
        position: absolute;
        z-index: 10;
        background-color: #6b8dd4;
        width: 100vw;
        height: 44%;
        bottom: 0;
        left: -20px;
    }
    
    .Hero .detail > div{
        display: flex;
        position: relative;
        z-index: 20;
        flex-direction: column;
        gap: 20px;
        align-items: start;
    }

    .Hero .detail h1{
        font-size: 34px;
        line-height: 110%;
    }
    
    .Hero .detail h2{
        font-size: 39px;
        line-height: 110%;
    }
    
    .Hero .detail ul{
        padding-right: 0%;
        margin-left: 20px;
        margin-bottom: 10px;
    }

    .Hero .detail p{
        padding-right: 0;
        font-size: 16px;
    }

    .Hero .detail .btn{
        width: 100%;
        text-align: center;
    }

    .Hero .detail .line{
        display: none;
    }
}