@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.Container {
  padding-left: 77px;
  padding-right: 77px;
  max-width: 1600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}



@media (max-width: 999px) {
  
.Container {
  padding-left: 30px;
  padding-right: 30px;
}
}
@media (min-width: 600px) {
  .Mob {
    display: none !important;
  }
}
@media (max-width: 599px) {
  .Container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .desktop {
    display: none !important;
  }
}
