.Process{
    /* margin: 2rem 0 3rem; */
    /* padding-top: 1rem; */
    width: 90%;
    margin-bottom: 4rem;
    overflow: hidden;

}
.ProcessWrap{
    margin: 4rem 0 20px;
}
.Process h2{
  font-size: 50px;
  line-height: 120%;
  margin: 16px 0 0;
  text-align: center;
}
.Process .subheading{
  font-size: 20px;
  line-height: 120%;
  font-weight: 600;
  margin: 40px 0 20px;
}
.Process p{
  font-size: 18px;
  line-height: 180%;
  margin: 20px 0;
}
.Process ol{
  font-size: 18px;
  line-height: 180%;
  margin: 16px 0 0;
  padding-left: 20px;


}
@media (max-width:999px) {
    .Process{
      width: 100%;

    }
    .Process h2{
      font-size: 30px;
    }
    .Process .subheading{
      font-size: 19px;
    }
    .Process p{
      font-size: 17px;
    }
    .Process ol{
      font-size: 17px;
      padding-left: 20px;
    }
  
}
@media (max-width:599px) {
    .Process{
        /* margin: 2rem 0 3rem; */
        /* padding-top: 1rem; */
        width: 100%;
        margin-bottom: 3rem;
    }
    .ProcessWrap{
        margin: 3rem 0 20px;
    }
    .Process h2{
      font-size: 36px;
    }
    .Process .subheading{
      font-size: 18px;
      margin: 40px 0 20px;
    }
    .Process p{
      font-size: 16px;
      margin: 20px 0;
    }
    .Process ol{
      font-size: 16px;
      margin: 16px 0 0;
      padding-left: 20px;
    
    
    }
  
}